<div [hidden]="!((!hasProvidedWork && isOpenFromDentbox != true) || (hasProvidedWork && isOpenFromDentbox))"
  class="row chat-heading ch-heading" [ngClass]="hasProvidedWork ? 'w-max': 'col-xs-10 col-sm-8 col-md-8 col-lg-10'">

  <div class=" heading-avatar col-xs-9 col-sm-7 col-md-7 col-lg-7">
    <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined">
      <div class="heading-avatar-icon work-detail-avatar" data-placement="bottom"
        title="{{'common.click_more_detail' | translate}}">
        <div class="img-left">
          <img *ngIf="work.profileImagePath" src="{{ work.profileImagePath }}" alt="profile-image-path">
        </div>
        <a class="right-bar-toggle waves-effect waves-light avatar-default">
          <label class="no-margin" [ngClass]="[
        work.state === 'active' ? 'active-avatar' : '',
        work.state === 'pending' ? 'pending-avatar' : '',
        work.state === 'inprogress' ? 'inprogress-avatar' : '',
        work.state === 'finalized' ? 'closed-avatar' : '',
        work.state === 'closed' ? 'closed-avatar' : '']" style="border-radius: 50%;"
            *ngIf="!work.profileImagePath"></label>
          <span class="fa fa-info"></span>
        </a>
      </div>
      <div class="heading-name" *ngIf="clinicCode === undefined">
        <a class="heading-name-meta h-name-new" *ngIf="isHidePatient == false">{{ work.patientName + ' ' +
          work.patientSurname }}
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <a class="heading-name-meta h-name-new" *ngIf="isHidePatient === true">
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work sentence-ellipsis">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>
      <div class="heading-name" *ngIf="clinicCode !== undefined">
        <a class="heading-name-meta h-name-new">{{ work.patientName + ' ' + work.patientSurname }}
          <span class="id-name">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work sentence-ellipsis">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>

    </div>
  </div>
  <div class="col-xs-3 col-sm-5 col-md-5 col-lg-5">
    <div class="d-flex float-right">

      <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined"
        class=" heading-dot">
        <ul class="nav navbar-nav navbar-right pull-right d-flex align-items-center flex-row">
          <li>
            <select [(ngModel)]="work.state" (change)="OnChangeWorkStatus()"
              [ngStyle]="{'background': (work.state === 'pending') ? '#F57C00': (work.state === 'active') ? '#388E3C' : (work.state === 'closed') ? '#607D8B': (work.state === 'rejected') ? '#dd3917' : (work.state === 'finalized') ? '#607D8B' : ''}">
              <option [disabled]="user_type === 'clinic'" value="active">{{ 'resource.active' | translate }}</option>
              <option [disabled]="user_type === 'clinic'" value="finalized">{{ 'resource.finalized' | translate }}
              </option>
              <option [disabled]="user_type === 'lab'" value="closed">{{ 'resource.closed' | translate }}</option>
              <option [disabled]="work.state === 'finalized' && user_type === 'clinic'" value="pending">{{
                'resource.pending' | translate }}</option>
              <option [disabled]="disableRejected" value="rejected">{{ 'resource.rejected' | translate }}</option>
            </select>
          </li>
          <li>
            <label *ngIf="work.entryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
              title="{{ 'resource.entryDate' | translate }}">
              <i class="fa fa-angle-down m-r-5" aria-hidden="true"></i>{{ (work.entryDate) | date: 'dd-MM-yyyy'
              }}</label>
            <label *ngIf="work.deliveryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
              title="{{ 'resource.deliverDate' | translate }}">
              <i class="fa fa-angle-up m-r-5" aria-hidden="true"></i>{{ (work.deliveryDate) | date: 'dd-MM-yyyy'
              }}</label>
          </li>
          <!-- <li class="hamberger-rightbar">
          <i class="fa fa-bars  pull-right right-bar-toggle waves-effect waves-light" aria-hidden="true"></i>
        </li> -->
        </ul>
      </div>

      <div class=" menu-lang-logout">
        <div class="btn-group">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="fa fa-bars"> </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" *ngIf="user_type === 'clinic'" (click)="_commonUiService.logOut()">
              <div class="" >
                <span ><span class="fa fa-sign-out me-3"></span>
                  {{'authenticate.LOG_OUT' | translate}}</span>
              </div>
            </a>
            <a class="dropdown-item submenu">
              <span class="fa fa-language me-3"></span>{{'common.langauges' | translate}}
              <ul class="submenu-list">
                <li (click)="changeLanguage('en')" [ngClass]="{'active-lng-color': activeLang =='en', 'deactive-lng-color': activeLang != 'en'}">
                  <a >{{ 'resource.english' | translate }}</a>
                </li>
                <li (click)="changeLanguage('es')" [ngClass]="{'active-lng-color': activeLang =='es', 'deactive-lng-color': activeLang != 'es'}"> 
                  <a >{{ 'resource.spanish' | translate }}</a>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </div>

      <div class="text-right version-icon">
        <label [ngbPopover]="version" popoverClass="popover-lab" triggers="mouseenter:mouseleave" placement="left"
          class="mb-0">
          <i class="fa fa-question-circle" aria-hidden="true"></i>
        </label>
        <ng-template #version>
          <label>{{'resource.product_version' | translate}}: {{ appVersion }}</label>
        </ng-template>
      </div>
    </div>
  </div>

</div>
<!-- Heading End -->


<!-- Novalab work page chat panel open div -->
<div [hidden]="!(hasProvidedWork && isOpenFromDentbox != true)"
  class="row chat-heading ch-heading novalab-work-dentbox-chat-header"
  [ngClass]="hasProvidedWork ? 'w-max': 'col-xs-10 col-sm-8 col-md-8 col-lg-10'">

  <div class=" heading-avatar col-xs-7 col-sm-7 col-md-7 col-lg-7">
    <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined">
      <div class="heading-avatar-icon work-detail-avatar" data-placement="bottom"
        title="{{'common.click_more_detail' | translate}}">
        <div class="img-left">
          <img *ngIf="work.profileImagePath" src="{{ work.profileImagePath }}" alt="profile-image-path">
        </div>
        <a class="right-bar-toggle waves-effect waves-light avatar-default">
          <label class="no-margin" [ngClass]="[
        work.state === 'active' ? 'active-avatar' : '',
        work.state === 'pending' ? 'pending-avatar' : '',
        work.state === 'inprogress' ? 'inprogress-avatar' : '',
        work.state === 'finalized' ? 'closed-avatar' : '',
        work.state === 'closed' ? 'closed-avatar' : '']" style="border-radius: 50%;"
            *ngIf="!work.profileImagePath"></label>
          <span class="fa fa-info"></span>
        </a>
      </div>
      <div class="heading-name" *ngIf="clinicCode === undefined">
        <a class="heading-name-meta h-name-new sentence-ellipsis" *ngIf="isHidePatient == false">{{ work.patientName + '
          ' +
          work.patientSurname }}
          <span class="id-name fs-4 sentence-ellipsis">{{ work.patientHistoryNumber }}</span>
        </a>
        <a class="heading-name-meta h-name-new" *ngIf="isHidePatient === true">
          <span class="id-name fs-4 sentence-ellipsis">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work sentence-ellipsis">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>
      <div class="heading-name" *ngIf="clinicCode !== undefined">
        <a class="heading-name-meta h-name-new">{{ work.patientName + ' ' + work.patientSurname }}
          <span class="id-name fs-4 sentence-ellipsis">{{ work.patientHistoryNumber }}</span>
        </a>
        <small class="type-pf-work sentence-ellipsis">{{ work.description }}</small>
        <span class="heading-online">{{ work.state }}</span>
      </div>

    </div>
  </div>
  <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-3">
    <div class="text-right version-icon">
      <label [ngbPopover]="version" popoverClass="popover-lab" triggers="mouseenter:mouseleave" placement="left"
        class="mb-0">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
      </label>
      <ng-template #version>
        <label>{{'resource.product_version' | translate}}: {{ appVersion }}</label>
      </ng-template>
    </div>
    <div [hidden]="work == null || work == undefined || work._id == null || work._id == undefined" class=" heading-dot">
      <ul class="nav navbar-nav navbar-right pull-right d-flex align-items-center flex-row">
        <li>
          <select [(ngModel)]="work.state" (change)="OnChangeWorkStatus()"
            [ngStyle]="{'background': (work.state === 'pending') ? '#F57C00': (work.state === 'inprogress') ? '#F57C00' : (work.state === 'active') ? '#388E3C' : (work.state === 'closed') ? '#607D8B': (work.state === 'rejected') ? '#dd3917' : (work.state === 'finalized') ? '#607D8B' : ''}">
            <option [disabled]="user_type === 'clinic'" value="active">{{ 'resource.active' | translate }}</option>
            <option [disabled]="user_type === 'clinic'" value="finalized">{{ 'resource.finalized' | translate }}
            </option>
            <option [disabled]="user_type === 'lab'" value="closed">{{ 'resource.closed' | translate }}</option>
<<<<<<< HEAD
            <option [disabled]="work.state === 'finalized' && user_type === 'clinic'" value="pending">{{
              'resource.pending' | translate }}</option>
=======
            <option [disabled]="work.state === 'finalized' && user_type === 'clinic'" value="pending">{{ 'resource.pending' | translate }}</option>
            <option [disabled]="work.state === 'finalized'" value="inprogress">{{ 'resource.inprogress' | translate }}</option>
>>>>>>> main
            <option [disabled]="disableRejected" value="rejected">{{ 'resource.rejected' | translate }}</option>
          </select>
        </li>
        <li class="floatstyle m-r-15">
          <label *ngIf="work.entryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
            title="{{ 'resource.entryDate' | translate }}">
            <i class="fa fa-angle-down m-r-5" aria-hidden="true"></i>{{ (work.entryDate) | date: 'dd-MM-yyyy' }}</label>
          <label *ngIf="work.deliveryDate != undefined" class="lblstyle" data-toggle="tooltip" data-placement="left"
            title="{{ 'resource.deliverDate' | translate }}">
            <i class="fa fa-angle-up m-r-5" aria-hidden="true"></i>{{ (work.deliveryDate) | date: 'dd-MM-yyyy'
            }}</label>
        </li>
      </ul>
    </div>
  </div>

</div>
<!--/ Novalab work page chat panel open div -->