import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CLINICS, WORKS } from '../common/endpoints';
import { PaginationResponse } from '../common/pagination';

@Injectable()
export class ExpandViewService {

  private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION;

  constructor(private http: HttpClient) { }

  public getPatientListByCenterId(centerGuid: string) {
    return this.http.get(`${this.url}${WORKS.GET_PATIENT_LIST_BY_CENTER_ID}${centerGuid}`)
      .pipe(catchError(err => of(err)));
  }

  public getCollaboratorListByCenterId(centerGuid: string) {
    return this.http.get(`${this.url}${WORKS.GET_COLLABORATOR_LIST_BY_CENTER_ID}${centerGuid}`)
      .pipe(catchError(err => of(err)));
  }

  // get all clinics
  public getClinicsByCenterGuid(centerGuid: string) {
    return this.http.get(`${this.url}${CLINICS.GET_CLINICS_BY_CENTER_GUID}${centerGuid}`)
      .pipe(catchError(err => of(err)));
  }

  public getPaginatedWorkList(queryString?: URLSearchParams): Observable<PaginationResponse> {
    return this.http.get(`${this.url}${WORKS.GET_PAGINATED_WORK_LIST}${queryString ? '?' + queryString : ''}`)
      .pipe(map(data => data as PaginationResponse));
  }

}

