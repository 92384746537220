<div class="fixed-sidebar">
  <!-- Heading -->
  <div id="breakpoints" class="row heading sidebar-sections top-left-side-bar">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="form-group has-feedback ">
        <div class="col-xs-12 col-sm-10 col-md-10 col-lg-12">
          <div class="heading-avatar-icon pt-2 m-r-10">
            <a class="left-bar-toggle waves-effect waves-light">
              <img *ngIf="user.logoPath" [src]="sanitizer.bypassSecurityTrustResourceUrl(user.logoPath)"
                id="avatar-image" class="img-circle profile-picture" alt="Avatar Logo Path">
              <p id="avatar-letters" class="avatar" *ngIf="!user.logoPath"
                [attr.data-letters]="getFirstTwoLetters(user.name)"></p>
            </a>
          </div>
          <div class="user-info">
            <h5 class="username sentence-ellipsis" data-toggle="tooltip" data-placement="right" title="{{user.name}}">{{user.name}}</h5>
            <h6 class="email sentence-ellipsis"  data-toggle="tooltip" data-placement="right" title="{{user.email}}">
              <em class="fa fa-envelope" aria-hidden="true"></em> {{user.email}}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Heading End -->

  <!-- Heading -->
  <div class="row heading sidebar-sections mtop">
    <div class="col-sm-12 col-md-12 col-lg-12 sidebar-sections-inner pr-2 pl-6">
      <div class="form-group has-feedback">
        <input id="searchText" type="text" class="form-control" #search (keyup)="onKey(search.value)" name="searchText"
          placeholder="{{ 'resource.search' | translate }}">
        <span class="glyphicon glyphicon-search form-control-feedback"></span>
      </div>
    </div>
  </div>

  <div class="sort-box-inner">
    <div id="breakpoints" class="row sort-box">
      <div #stateListDivElement class="col-xs-9 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 status-div">
        <label class="lblc">{{ 'resource.filterwWithStatus' | translate }}</label>
        <ng-container *ngFor="let item of stateList">
          <button *ngIf="item === 'active'" type="button" name="active" id="active-status" #active
            [ngClass]="[active.value === 'clicked'? 'c-active-border' : '']" (click)="OnChangeStatus(item, $event)"
            class="btn btn-link sort-btns-gutter c-active">
            <em class="fa fa-circle fa--1x c-activeb"></em> {{ 'resource.active' | translate }}
          </button>

          <button *ngIf="item === 'pending'" type="button" name="pending" id="pending-status" #pending
            [ngClass]="[pending.value === 'clicked'? 'c-pending-border' : '']" (click)="OnChangeStatus(item, $event)"
            class="btn btn-link sort-btns-gutter c-pending">
            <em class="fa fa-circle fa-1x c-pendingb"></em> {{ 'resource.pending' | translate }}
          </button>

          <button *ngIf="item === 'inprogress'" type="button" name="inprogress" id="inprogress-status" #inprogress
            [ngClass]="[inprogress.value === 'clicked'? 'c-inprogress-border' : '']" (click)="OnChangeStatus(item, $event)"
            class="btn btn-link sort-btns-gutter c-inprogress">
            <em class="fa fa-circle fa-1x c-inprogressb"></em> {{ 'resource.inprogress' | translate }}
          </button>

          <button *ngIf="item === 'closed'" type="button" name="closed" id="closed-status" #closed
            [ngClass]="[closed.value === 'clicked'? 'c-closed-border' : '']" (click)="OnChangeStatus(item, $event)"
            class="btn btn-link sort-btns-gutter c-declined">
            <em class="fa fa-circle fa-1x c-declinedb"></em>{{ 'resource.closed' | translate }}
          </button>

          <button *ngIf="item === 'rejected'" type="button" name="rejected" id="rejected-status" #rejected
            [ngClass]="[rejected.value === 'clicked'? 'c-rejected-border' : '']" (click)="OnChangeStatus('rejected', $event)"
            class="btn btn-link sort-btns-gutter c-rejected">
          <em class="fa fa-circle fa-1x c-rejectedb"></em> {{ 'resource.rejected' | translate }}
        </button>

        <button *ngIf="item === 'finalized'" type="button" name="finalized" id="finalized-status" #finalized
            [ngClass]="[finalized.value === 'clicked'? 'c-finalized-border' : '']" (click)="OnChangeStatus(item, $event)"
            class="btn btn-link sort-btns-gutter c-declined">
            <em class="fa fa-circle fa-1x c-declinedb"></em>{{ 'resource.finalized' | translate }}
        </button>

        </ng-container>
      </div>

      <div class="col-xs-3 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 p-0 switch">
        <ng-template #lab> {{'resource.showItemLocation' | translate}} <br />
          <strong> {{'resource.in' | translate}} {{'resource.labCaps' | translate}} </strong>
        </ng-template>
        <button class="btn-list-view" (click)="openDentboxWorkList()" *ngIf="'workListExpandView' | featureFlag">
          <em class="fa fa-list" aria-hidden="true"></em>
        </button>
        <button class="btn-lab" type="button" [ngbPopover]="lab" popoverClass="popover-lab"
          (click)="OnWorkItemClick('l', $event)" triggers="mouseenter:mouseleave"></button>
        <ng-template #clinic> {{'resource.showItemLocation' | translate}} <br />
          <strong> {{'resource.in' | translate}} {{'resource.clinicCaps' | translate}} </strong>
        </ng-template>
        <button type="button" class="btn-clinic" [ngbPopover]="clinic" popoverClass="popover-clinic"
          (click)="OnWorkItemClick('c', $event)" triggers="mouseenter:mouseleave"></button>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-4 m-b-5 m-t-5">
        <ng-container *ngIf="this.user_type != null && this.user_type == 'clinic'">
          <div class="form-group has-feedback m-b">
            <select #dropdown_lab class="form-control dropdown_clinic_select"
              (change)="OnLabDropDownSelected(dropdown_lab.value, $event)">
              <option value="all">{{ 'resource.all' | translate }}</option>
              <option *ngFor="let item of relatedLabs" [value]="item._id">{{item.name}}</option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="this.user_type != null && this.user_type == 'lab'">
          <div class="form-group has-feedback m-b">
            <select #dropdown_clinic class="form-control dropdown_clinic_select"
              (change)="OnClinicDropDownSelected(dropdown_clinic.value, $event)">
              <option value="all">{{ 'resource.all' | translate }}</option>
              <option *ngFor="let item of relatedClinics" value="{{item._id}}">{{item.name}}</option>
            </select>
          </div>
        </ng-container>
      </div>
      <div class="col-xs-4 col-sm-12 col-md-12 col-lg-12 col-xxl-4 filter-date-type" [ngClass]="[commonUiService.ScreenWidth() === 'xxl'? 'p-r-5' : '']">
        <div class="form-group has-feedback m-b">
          <select #dropdown_dateType class="form-control" (change)="OnDateTypeSelected(dropdown_dateType.value, $event)" [(ngModel)]="dateType">
            <option value="1" selected>{{ 'resource.order_date' | translate }}</option>
            <option value="2">{{ 'resource.delivery_date' | translate }}</option>
          </select>
        </div>
      </div>

      <div class="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xxl-4 p-r-5 filter-date-from"
        [ngClass]="[commonUiService.ScreenWidth() === 'xxl'? 'p-l-5' : '']">
        <div class="form-group">
          <div class="input-group calendar-wrap dp-fixed-position">
            <input class="form-control calendar-input" placeholder="{{ 'resource.from_date' | translate }}" name="dpfrom" [(ngModel)]="fromDate"
              ngbDatepicker #df="ngbDatepicker" (click)="df.toggle()" autocomplete="off" (ngModelChange)="FilterByDate()">
          </div>
        </div>
      </div>

      <div class="col-xs-4  col-sm-6 col-md-6 col-lg-6 col-xxl-4 p-l-5 filter-date-to">
        <div class="form-group">
          <div class="input-group calendar-wrap dp-fixed-position">
            <input class="form-control calendar-input" placeholder="{{ 'resource.to_date' | translate }}" name="dpto" [(ngModel)]="toDate"
              ngbDatepicker #dt="ngbDatepicker" (click)="dt.toggle()" autocomplete="off" (ngModelChange)="FilterByDate()">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- sideBar -->
<div class="inbox-widget nicescroll mx-box" tabindex="5000">
  <div class="row">
    <div class="scroll-window-wrapper">
      <div class="scroll-window" [ngClass]="[user_type === 'lab' ? 'hide-plus-button': '']">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 sidebar-scrollable p-r-0">
          <ng-container *ngFor="let item of workList">
            <a class="work-item" (click)="loadChat(item)">
              <div class="inbox-item card-b border-style d-flex" (mouseenter)="mouseEnter(item.state, $event)"
                (mouseleave)="mouseLeave(item.state, $event)" [ngClass]="[
            item.state === 'closed' ? 'c-work-state-decline-border' : '',
            item.state === 'finalized' ? 'c-work-state-finalized-border' : '',
            item.state === 'pending' ? 'c-work-state-pending-border' : '',
            item.state === 'inprogress' ? 'c-work-state-inprogress-border' : '',
            item.state === 'rejected' ? 'c-work-state-confirm-border' : '',
            item.state === 'active' ? 'c-work-state-active-border' : '',
            item.state === '' ? 'c-work-state-pending-border' : '',
            (item.state === 'active' && selectedItem['_id'] === item['_id']) ? 'selected-active-work-item' : '',
            (item.state === 'pending' && selectedItem['_id'] === item['_id']) ? 'selected-pending-work-item' : '',
            (item.state === 'inprogress' && selectedItem['_id'] === item['_id']) ? 'selected-inprogress-work-item' : '',
            (item.state === 'rejected' && selectedItem['_id'] === item['_id']) ? 'selected-rejected-work-item' : '',
            (item.state === 'finalized' && selectedItem['_id'] === item['_id']) ? 'selected-finalized-work-item' : '',
            (item.state === 'closed' && selectedItem['_id'] === item['_id']) ? 'selected-closed-work-item' : '']">
                <span class="msg-indicater" *ngIf="item.isAttachmentsAvailable"><em class="fa fa-paperclip"
                    aria-hidden="true"></em></span>
                <div class="inbox-item-img col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <img *ngIf="item.profileImagePath" src="{{ item.profileImagePath }}" class="rounded-circle"
                    alt="patient-image-path">
                  <p *ngIf="!item.profileImagePath && item.color !== ''" [ngClass]="'work-avatar'"></p>
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                    <p *ngIf="user_type === 'clinic'" class="inbox-item-author cicle-style patient-name">
                      {{ item.patientName + ' ' + item.patientSurname }}
                      <span class="span-float-style ref-numbers"
                        [ngClass]="['lg', 'xl', 'xxl'].includes(commonUiService.ScreenWidth()) ? 'm-l-10' : ''">{{ item.patientHistoryNumber }} {{
                        item.workReferenceNo ? ' | ' + item.workReferenceNo : '' }}</span>
                      </p>
                      <p *ngIf="user_type === 'clinic'" class="inbox-item-author cicle-style">
                        {{ item.labName ? item.labName : '' }}
                      </p>
                      <ng-template #clinicNamePopOver> {{ item.clinicName }} </ng-template>
                      <p *ngIf="user_type === 'lab'" class="inbox-item-author" placement="bottom"
                        popoverClass="popover-clinic popover-tooltip-view" triggers="mouseenter:mouseleave">
                        {{ item.patientName + ' ' + item.patientSurname }}
                        {{ (item.workNo ? ' - ' + item.workNo : '') }}
                      </p>
                  <p *ngIf="user_type === 'lab'" class="inbox-item-author" [ngbPopover]="clinicNamePopOver" placement="bottom"
                    popoverClass="popover-clinic popover-tooltip-view" triggers="mouseenter:mouseleave">
                    {{ item.clinicName ? item.clinicName : '' }}
                  </p>
                  <p class="inbox-item-text"
                    [ngStyle]="{'color': (item.state === 'pending') ? '#F57C00': (item.state === 'inprogress') ? '#F57C00': (item.state === 'active') ? '#388E3C' : (item.state === 'closed') ? '#607D8B':  (item.state === 'finalized') ? '#607D8B': ''}">
                    {{(item.description.length > 60)? (item.description | slice:0:60)+'..':(item.description)}}</p>
                  <p class="date-color">
                    <!-- <small class="text-muted" *ngIf="item.entryDate">{{ getTimeDifference(item.entryDate) }}</small> -->
                    <span *ngIf="item.entryDate">{{(item.entryDate) | date: 'dd-MM-yyyy'}}</span>
                    <small class="text-muted m-l-5">{{ item.novalabUser ? item.novalabUser : ''}}</small>
                    </p>
                    <span *ngIf="getUnSeenCount(item) > 0" class="badge notify-color span-float-style">{{
                      getUnSeenCount(item) }}</span>
                </div>
              </div>
            </a>
          </ng-container>
        </div>
        <ng-container *ngIf="user_type === 'clinic' && !hasGesden"><button class="add-work" (click)="onOpenWorkModal()"><em
              class="ion-plus"></em></button></ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Sidebar End-->
