export class DentboxWorkListFilterModel{
  status: any[];
  dateType?: any;
  isToday?: boolean;
  fromDate?: any;
  toDate?: any;
  clinicId?: any;
  collaborator?: any;
  labId?: any;
  patient?: any;
  location?: any;

}
