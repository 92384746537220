<div class="wrapper pop fadeInDown">
   <div class="logo">
      <img src="../../../assets/images/dentbox_logo.png" alt="Dentbox">
   </div>
   <div id="formContent">
      <!-- Tabs Titles -->
      <!-- Icon -->
      <div class="fadeIn first">
         <h3>{{ 'authenticate.reset_your_password' | translate }}</h3>
      </div>
      <!-- Login Form -->
      <form autocomplete="off" #forgotForm="ngForm" [formGroup]="resetFormGroup" #resetForm="ngForm">
      <div class="form-group">
         <label>{{ 'authenticate.your_new_password' | translate }}</label>
         <input id="password" type="password" class="fadeIn second" autocomplete="off" [(ngModel)]="rpvm.password" formControlName="password" required>
         <div *ngIf="resetFormGroup.get('password').dirty && resetFormGroup.get('password').errors?.required">
            <span class="error error-signin">{{ 'authenticate.validations.password_required' | translate }}</span>
         </div>
      </div>
      <div class="form-group">
         <label>{{ 'authenticate.your_confirm_password' | translate }}</label>
         <input id="confirmPassword" type="password" class="fadeIn second" autocomplete="off" [(ngModel)]="rpvm.confirmPassword" formControlName="confirmPassword" required>
         <div
            *ngIf="resetFormGroup.get('confirmPassword').dirty && resetFormGroup.get('confirmPassword').errors?.required">
            <span class="error error-signin">{{ 'authenticate.confirmation_required' | translate }}</span>
         </div>
         <div *ngIf="resetFormGroup.get('confirmPassword').dirty && resetFormGroup.get('confirmPassword').invalid ||
            resetFormGroup.errors?.MatchPassword">
            <span class="error error-signin">{{ 'authenticate.confirm_password_not_match' | translate }}</span>
         </div>
      </div>
      <div class="form-group">
         <label>{{ 'authenticate.code_verification' | translate }}</label>
         <input type="text" class="fadeIn second" autocomplete="off" [(ngModel)]="rpvm.token" formControlName="token" required>
         <div *ngIf="resetFormGroup.get('token').dirty && resetFormGroup.get('token').errors?.required">
            <span class="error error-signin">{{ 'authenticate.token_is_required' | translate }}</span>
         </div>
         <div *ngIf="resetFormGroup.get('token').touched && resetFormGroup.hasError('invalidVerificationCode')">
            <span class="error error-signin">{{ 'authenticate.validations.invalid_verification_code' | translate }}</span>
         </div>
      </div>
      <button class="fadeIn fourth login" type="button" (click)="resetPassword(resetForm, $event)">
      {{ 'authenticate.reset_password' | translate }}
      </button>
      </form>
      <div id="formFooter">
         <a [routerLink]="['/dsa-login']">{{ 'authenticate.back_to_login' | translate }}</a>
      </div>
   </div>
</div>