import { TranslateService } from '@ngx-translate/core';
import { SocketService } from './../../../service/sockets.service';
import { Message } from './../../../model/message';
import { WorkService } from './../../../service/work.service';
import { ClinicService } from './../../../service/clinic.service';
import { Component, Inject, OnInit, OnDestroy, Input } from '@angular/core';
import { Work } from '../../../model/work';
import { Subscription } from 'rxjs';
import { ChatService } from '../../../service/chat.service';
import { DatePipe } from '@angular/common';
import { Clinic } from '../../../model/clinic';

@Component({
  selector: 'chat-heading',
  templateUrl: './chat-heading.component.html',
  styleUrls: ['./chat-heading.component.css']
})
export class ChatHeadingComponent implements OnInit, OnDestroy {

  constructor(private _chatService: ChatService, public datepipe: DatePipe, private _clinicService: ClinicService,
    private _workService: WorkService, private _socketService: SocketService, private translate: TranslateService) { }

  private subscriber: Subscription;
  public work: Work;
  public isHome: number;
  public isHidePatient: boolean;
  public clinicCode: string;
  public user_type: string;
  public user_id: string;
  public translateLabels: string[] = [];

  public sent = "sent";
  public delivered = "delivered";
  public read = "read";
  appVersion = '';
  disableRejected: boolean = true;

  @Input() hasProvidedWork: boolean;

  ngOnInit() {
    if (require('../../../../../package.json') && require('../../../../../package.json') != undefined) {
      this.appVersion = require('../../../../../package.json').version;
    }

    this.subscriber = this._chatService.currentSelectedWork.subscribe(
      data => {
        this.work = data;
        if (data.clinicId !== undefined) {
          this._clinicService.getClinicById(data.clinicId).subscribe(((clinic: Clinic) => {
            this.isHidePatient = clinic.isHidePatientInfo;
          }));
        }
      });
    this.subscriber = this._chatService.isHome.subscribe(isHome => this.isHome = isHome);
    this.subscriber = this._chatService.translateLabel.subscribe(translateLabel => this.translateLabels = translateLabel);
    this.clinicCode = JSON.parse(sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')).clinicCode;
    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    this.user_id = sessionStorage.getItem('4CDC84B1E0607D91E3C7BBDE07EECC6A');
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  public getFirstTwoLetters(patientName: string) {
    if (patientName) {
      const arr = patientName.split(' ');
      if (arr.length > 1) {
        return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
      } else {
        return (arr[0].substring(0, 2)).toUpperCase();
      }
    }
  }

  public changeDateFormat(date: string) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  public OnChangeWorkStatus() {
    if (this.work.state == 'closed') {
      const currentDate = new Date().toISOString();
      this.work.deliveryDate = this._chatService.getConvertedDate(currentDate);
    }

    this._socketService.updateWorkEmitter(this.work);
    this.sendMessage(this.work.state);
  }

  async sendMessage(state: string): Promise<void> {
    const message = new Message();
    message.text = this.translateLabels['workStatusChanged'] + (state === 'closed' ?
      this.translateLabels['closed'] : (state === 'finalized') ? this.translateLabels['finalized'] : ((state === 'pending') ? this.translateLabels['pending'] :  this.translateLabels['active']));
    message.workId = this.work['_id'];
    message.readDateTime = '';
    message.deliveredDateTime = '';
    message.state = this.sent;

    switch (this.user_type) {
      case 'clinic':
        message.owner = 'clinic';
        message.senderId = this.work.clinicId;
        message.recieverId = this.work.labId;
        break;
      case 'lab':
        message.owner = 'lab';
        message.senderId = this.work.labId;
        message.recieverId = this.work.clinicId;
        break;
      default:
        console.log('no user provided!');
        message.owner = 'clinic';
        break;
    }

    this._socketService.sendMessageEmitter(message);
    this._socketService.getUnseenMessagesEmitter();
    await this._chatService.setAndPublishMessage(this.work, undefined, undefined);
    this._socketService.getWorksEmitter(this.user_id, this.user_type);
    this._socketService.getAttachmentsByWorkIdEmitter(this.work['_id']);
  }
}
