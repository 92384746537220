import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChatComponent } from './components/chat/chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatSideBarComponent } from './components/chat/chat-side-bar/chat-side-bar.component';
import { ChatHeadingComponent } from './components/chat/chat-heading/chat-heading.component';
import { MessageBoxComponent } from './components/chat/message-box/message-box.component';
import { ChatService } from './service/chat.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WorkService } from './service/work.service';
import { MessageService } from './service/message.service';
import { HttpClient } from '@angular/common/http';
import { RightbarComponent } from './components/chat/rightbar/rightbar.component';
import { LabService } from './service/lab.service';
import { ChatPublishService } from './service/chat-publish-service';
import { DatePipe } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthorizationService } from './service/authorization.service';
import { UploadService } from './service/upload.service';
import { ClinicService } from './service/clinic.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LeftbarComponent } from './components/chat/leftbar/leftbar.component';
import { SocketService } from './service/sockets.service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LinkyModule } from 'angular-linky';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { WorkChatComponent } from './components/work-chat/work-chat.component';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppErrorHandler } from './service/error-handler';
import { HttpLoaderFactory, ngxUiLoaderConfig } from './common/global';
import { AuthComponent } from './components/auth/auth.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';
import { HttpConfigInterceptor } from './common/http-config-interceptor';
import { DsaLoginComponent } from './components/dsa-login/dsa-login.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CenterSelectionPopupComponent } from './components/center-selection-popup/center-selection-popup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AddWorkComponent } from './components/chat/add-work/add-work.component';
import { NovaSearchModule } from 'nova-search';
import { CustomDatepickerI18nService, I18n } from './service/custom-date-picker-i18n.service';
import { DateParserFormatter } from './common/date-parser-formater';
import { DentboxWorkRequestListComponent } from './components/dentbox-work-request-list/dentbox-work-request-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExpandViewService } from './service/expand-view.service';
import { ProfileService } from './service/profile.service';
import { FeatureFlagService } from './service/feature-flag.service';
import { FeatureFlagPipe } from './common/pipes/feature-flag.pipe';

export function loadFeatureFlags(featureFlagService: FeatureFlagService) {
  return () => featureFlagService.loadFeatureFlags().toPromise();
}

@NgModule({
    declarations: [
        AppComponent,
        ChatComponent,
        ChatSideBarComponent,
        ChatHeadingComponent,
        MessageBoxComponent,
        RightbarComponent,
        RightbarComponent,
        HomeComponent,
        LeftbarComponent,
        WorkChatComponent,
        AuthComponent,
        UnAuthorizedComponent,
        DsaLoginComponent,
        CenterSelectionPopupComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        AddWorkComponent,
    DentboxWorkRequestListComponent,
    FeatureFlagPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        NgSelectModule,
        NgbTooltipModule,
        BrowserAnimationsModule,
        NovaSearchModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        FileUploadModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ScrollToModule.forRoot(),
        LinkyModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgCircleProgressModule.forRoot({})
    ],
    providers: [
        ChatService,
        WorkService,
        MessageService,
        LabService,
        ChatPublishService,
        DatePipe,
        AuthorizationService,
        UploadService,
        ClinicService,
        SocketService,
        ExpandViewService,
        ProfileService,
        { provide: ErrorHandler, useClass: AppErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
      },
      {
        provide: APP_INITIALIZER,
        useFactory: loadFeatureFlags,
        deps: [FeatureFlagService],
        multi: true
      },
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
        { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
