import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ApiConnection } from './apiConnection';
import { Observable, of, throwError } from "rxjs";
import { PROFILE } from "../common/endpoints";
import { catchError } from "rxjs/operators";
import { WorkListProfile } from "../model/work-list-profile";

@Injectable()
export class ProfileService {
  private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION;

  constructor(private http: HttpClient) { }

  public getProfilesByClinicId(clinicId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}${PROFILE.GET_PROFILES_BY_CLINIC_ID}${clinicId}`)
      .pipe(
        catchError(err => {
          console.error('Error fetching profiles by clinic ID:', err);
          return throwError(() => new Error('Failed to fetch profiles by clinic ID'));
        })
      );
  }

  public getProfilesByLabId(labId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}${PROFILE.GET_PROFILES_BY_LAB_ID}${labId}`)
      .pipe(
        catchError(err => {
          console.error('Error fetching profiles by lab ID:', err);
          return throwError(() => new Error('Failed to fetch profiles by lab ID'));
        })
      );
  }

  saveProfile(profile: WorkListProfile): any {
    return this.http.post(ApiConnection.BASE_URL + ApiConnection.API_VERSION + PROFILE.SAVE_PROFILE, profile);
  }

  updateProfile(profile: WorkListProfile): any {
    return this.http.put(ApiConnection.BASE_URL + ApiConnection.API_VERSION + PROFILE.UPDATE_PROFILE + `${profile._id}`, profile);
  }

  deleteProfile(profile: WorkListProfile): any {
    return this.http.delete(ApiConnection.BASE_URL + ApiConnection.API_VERSION + PROFILE.DELETE_PROFILE + `${profile._id}`);
  }

}
