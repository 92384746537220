<div class="row navbar-header-common w-100 m-0">
    <div id="breakpoints" class="row heading sidebar-sections top-left-side-bar">
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <div class="form-group has-feedback ">
                <div class="col-xs-12 col-sm-10 col-md-10 col-lg-12">
                    <div class="heading-avatar-icon pt-2 mx-4 float-left">
                        <a class="left-bar-toggle waves-effect waves-light">
                          <img *ngIf="user.logoPath" [src]="sanitizer.bypassSecurityTrustResourceUrl(user.logoPath)"
                          id="avatar-image" class="img-circle profile-picture" alt="Avatar Logo Path">
                        <p id="avatar-letters" class="avatar" *ngIf="!user.logoPath"
                          [attr.data-letters]="getFirstTwoLetters(user.name)"></p>
                        </a>
                    </div>
                    <div class="user-info ms-2 float-left">
                        <h5 class="username sentence-ellipsis" data-toggle="tooltip" data-placement="right"  title="{{user.name}}">{{user.name}}
                        </h5>
                        <h6 class="email sentence-ellipsis" data-toggle="tooltip" data-placement="right" title="{{user.email}}">
                            <em class="fa fa-envelope" aria-hidden="true"></em> {{user.email}}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
  
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <div class="d-flex float-right">
          
          <div class=" menu-lang-logout mt-3">
            <div class="btn-group">
              <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="fa fa-bars">  </span>
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" *ngIf="user_type === 'clinic'" (click)="_commonUiService.logOut()">
                  <div class="">
                    <span><span class="fa fa-sign-out me-3"></span>
                      {{'authenticate.LOG_OUT' | translate}}</span>
                  </div>
                </a>
                <a class="dropdown-item submenu">
                  <span class="fa fa-language me-3"></span>{{'common.langauges' | translate}}
                  <ul class="submenu-list">
                    <li (click)="changeLanguage('en')" [ngClass]="{'active-lng-color': activeLang =='en', 'deactive-lng-color': activeLang != 'en'}">
                      <a >{{ 'resource.english' | translate }}</a>
                    </li>
                    <li (click)="changeLanguage('es')" [ngClass]="{'active-lng-color': activeLang =='es', 'deactive-lng-color': activeLang != 'es'}"> 
                      <a >{{ 'resource.spanish' | translate }}</a>
                    </li>
                  </ul>
                </a>
              </div>
            </div>
          </div>

          <div class="text-right version-icon">
            <label [ngbPopover]="version" popoverClass="popover-lab" triggers="mouseenter:mouseleave" placement="left" class="mb-0 icon-label">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </label>
            <ng-template #version>
              <label class="version-text">{{'resource.product_version' | translate}}: {{ appVersion }}</label>
            </ng-template>
          </div>
        </div>
        </div>
    </div>
</div>
