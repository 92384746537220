import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat } from '../model/date-format';
import { Lab } from '../model/lab';
import { Clinic } from '../model/clinic';
import { ClinicService } from './clinic.service';
import { LabService } from './lab.service';
import { ChatService } from './chat.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonUiService {
  _currentLanguage = 'en';
  breakpoint: string;

  public usertype;
  public user: any;

  constructor(private _translateService: TranslateService,  private _labService: LabService,
      private _clinicService: ClinicService, private _chatService: ChatService, private _router: Router) {
    if (sessionStorage.getItem('currentLang')) {
      this._currentLanguage = sessionStorage.getItem('currentLang');
    } else {
      this._currentLanguage = this._translateService.getBrowserLang();
    }
  }

  ToNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  ChangeNgbDateToCustomFormat(ngbDate): any {
    if (ngbDate && ngbDate.year) {
      return (
        ngbDate.year +
        '-' +
        this.GetWholeNumber(ngbDate.month.toString()) +
        '-' +
        this.GetWholeNumber(ngbDate.day.toString())
      );
    }
    return ngbDate;
  }

  ConvertToPickerDateFormat(dateString: string): DateFormat {
    if (dateString) {
      let formatedDate: string = dateString;
      if (formatedDate !== undefined || formatedDate != null) {
        formatedDate = formatedDate.substr(0, 10);
        const splitted_array: string[] = formatedDate.split('-');
        // tslint:disable-next-line: radix
        return new DateFormat(
          parseInt(splitted_array[2]),
          parseInt(splitted_array[1]),
          parseInt(splitted_array[0])
        );
      }
    }
  }

  private GetWholeNumber(num: string) {
    if (num) {
      if (num.length === 1) {
        num = num.padStart(2, '0');
      }
      return num;
    }
  }

  public GetPlaceHolderValue(): string {
    return (this._currentLanguage === 'en' || this._currentLanguage === undefined) ? 'dd/mm/yyyy' : 'dd/mm/aaaa';
  }

  public ScreenWidth(): string {
    const screenSize = window.innerWidth;
    if (screenSize >= 1400) {
      this.breakpoint = 'xxl';
    } else if (screenSize < 1400 && screenSize >= 1200) {
      this.breakpoint = 'xl';
    } else if (screenSize < 1200 && screenSize >= 992) {
      this.breakpoint = 'lg';
    } else if (screenSize < 992 && screenSize >= 768) {
      this.breakpoint = 'md';
    } else if (screenSize < 768 && screenSize >= 576) {
      this.breakpoint = 'sm';
    } else if (screenSize < 576) {
      this.breakpoint = 'xs';
    }
    return this.breakpoint;
  }

  public async changeLanguage(language: string): Promise<void> {
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    if (data) {
      this.user = JSON.parse(data);
    }

    this.usertype = window.sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
      
    if (language) {
      sessionStorage.setItem('currentLang', language); // save language in session storage
      this._translateService.use(language);
      if (this.usertype === 'lab') {
        this.user.language = language;
        this._labService.putLab(this.user as Lab).subscribe((res => {
        }));
      } else if (this.usertype === 'clinic') {
        this.user.language = language;
        this._clinicService.putClinic(this.user as Clinic).subscribe((res => {
        }));
      }
      sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(this.user));
    }
    this._chatService.setLabels();
  }

  logOut() {
    localStorage.setItem('logout', Date.now().toString()); // Trigger logout event
    sessionStorage.clear();
    localStorage.clear();
    this._router.navigate(['/dsa-login'], { replaceUrl: true });
  }
  
}
