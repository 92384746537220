export const CLINICS = {
  GET_CENTER_BY_GUID: 'clinics/getbyguid/',
  GET_CLINICS_BY_CENTER_GUID: 'clinics/getbycenterguid/',
};

export const LABS = {
  GET_CENTER_BY_GUID: 'labs/getbyguid/',
  GET_LABS_BY_CENTER_GUID: 'labs/getbycenterguid/',
};

export const WORKS = {
  GET_WORK_LIST_BY_CENTER_ID: 'works/getbycenterid/',
  SAVE_WORK: 'works/savework/',
  UPDATE_WORK: 'works/updatework/',
  DELETE_WORK: 'works/deletework/',
  GET_PATIENT_LIST_BY_CENTER_ID: 'works/getPatientListByCenterId/',
  GET_COLLABORATOR_LIST_BY_CENTER_ID: 'works/getCollaboratorListByCenterId/',
  GET_PAGINATED_WORK_LIST: 'works/expandview/getPaginatedWorkList'
};

export const USERS = {
  LOGIN: 'users/login/',
  FORGOT: 'users/forgot/',
  RESET: 'users/resetpassword/'
};

export const PROFILE = {
  GET_PROFILES_BY_CLINIC_ID: 'profile/getProfilesByClinicId/',
  GET_PROFILES_BY_LAB_ID: 'profile/getProfilesByLabId/',
  SAVE_PROFILE: 'profile/saveprofile/',
  UPDATE_PROFILE: 'profile/updateprofile/',
  DELETE_PROFILE: 'profile/deleteprofile/'
};
