import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { HomeComponent } from './components/home/home.component';
import { AuthComponent } from './components/auth/auth.component';
import { WorkChatComponent } from './components/work-chat/work-chat.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';
import { AuthGuard } from './common/auth-guard';
import { DsaLoginComponent } from './components/dsa-login/dsa-login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DentboxWorkRequestListComponent } from './components/dentbox-work-request-list/dentbox-work-request-list.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'general-chat', component: ChatComponent },
  { path: 'work-chat', component: WorkChatComponent, canActivate: [AuthGuard] },
  { path: 'dentbox-work-request-list', component: DentboxWorkRequestListComponent, canActivate: [AuthGuard] },
  { path: 'auth', data: { ticket: '' }, component: AuthComponent },
  { path: 'unauthorized', component: UnAuthorizedComponent },
  { path: 'dsa-login', component: DsaLoginComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'reset', component: ResetPasswordComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  declarations: []
})
export class AppRoutingModule {

}
