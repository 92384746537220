import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagService } from "src/app/service/feature-flag.service";

@Pipe({
  name: 'featureFlag'
})
export class FeatureFlagPipe implements PipeTransform {
  constructor(private featureFlagService: FeatureFlagService) {}

  transform(flag: string): boolean {
    return this.featureFlagService.isFeatureEnabled(flag);
  }
}
