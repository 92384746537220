import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Clinic } from 'src/app/model/clinic';
import { Lab } from 'src/app/model/lab';
import { CommonUiService } from 'src/app/service/common.ui.service';

declare var $: any;

@Component({
  selector: 'app-header-nav-bar',
  templateUrl: './header-nav-bar.component.html',
  styleUrls: ['./header-nav-bar.component.scss']
})
export class HeaderNavBarComponent implements OnInit {
  public user_type: string;
  public user_id: string;
  hasGesden: any;
  public user: Clinic | Lab;
  appVersion = '';
  activeLang : string = "";

  constructor(private _router: Router, public _commonUiService: CommonUiService) { }

  ngOnInit(): void {
    if (require('../../../../package.json') && require('../../../../package.json') != undefined) {
      this.appVersion = require('../../../../package.json').version;
    }

    $('.left-bar-toggle').on('click', function (e) {
      $('#wrapper').toggleClass('left-bar-enabled');
    });

    if (!sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C') || !sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF')
      || !sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')) { this._router.navigate(['/']); }


    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');

    if (data) {
      this.user = JSON.parse(data);
    }

    window.addEventListener('storage', (event) => {
      if (event.key === 'logout' && this.user_type === 'clinic') {
        this._commonUiService.logOut();
      }
    });

    this.activeLang=sessionStorage.getItem('currentLang');
  }

  public getFirstTwoLetters(patientName: string) {
    if (patientName) {
      const arr = patientName.split(' ');
      if (arr.length > 1) {
        return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
      } else {
        return (arr[0].substring(0, 2)).toUpperCase();
      }
    }
  }

  changeLanguage(language: string) {
    this.activeLang = language;
    this._commonUiService.changeLanguage(this.activeLang)
  }

}
