import { Injectable } from "@angular/core";
import { AuthModel } from "../model/auth-model";
import jwt_decode from 'jwt-decode';
import { Observable } from "rxjs";
import { ApiResponse } from "../common/api-response";
import { ApiConnection } from "./apiConnection";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION;
  userData = { id: '', name: '', centerCount: 0, hasGesden: false, defaultCenterGuid: undefined };

  constructor(private httpClient: HttpClient) {
    if (!this.userData.id) {
      this.setLoggedUser();
    }
  }

  setLoggedUser() {
    if (sessionStorage.CDFEE2BC43D63CAEAA3B169AD31E966C) {
      const data = jwt_decode(sessionStorage.CDFEE2BC43D63CAEAA3B169AD31E966C);
      if (data) {
        this.userData.id = data.id;
        this.userData.name = data.name;
        this.userData.centerCount = data.centerCount;
        this.userData.hasGesden = data.hasGesden;
        this.userData.defaultCenterGuid = data.defaultCenterGuid;
      }
    }
  }


  /**
  * create AuthModel object and returns ApiResponse
  */
  login(endpoint: string, item: AuthModel): Observable<ApiResponse> {
    return this.httpClient
      .post<ApiResponse>(`${this.url}${endpoint}`, item, { observe: 'response' as 'body' });
  }

  /**
   * post request
   */
  post(endpoint: string, item: any): Observable<ApiResponse> {
    return this.httpClient
      .post<ApiResponse>(`${this.url}${endpoint}`, item, { observe: 'response' as 'body' });
  }

}