import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private featureFlags: { [key: string]: boolean } = {};

  constructor(private http: HttpClient) {}

  loadFeatureFlags(): Observable<void> {
    return this.http.get<{ [key: string]: boolean }>('/assets/feature-flags.json').pipe(
      map(flags => {
        this.featureFlags = flags;
      })
    );
  }

  isFeatureEnabled(flag: string): boolean {
    return this.featureFlags[flag] || false;
  }
}
